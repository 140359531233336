/* src/Filters.css */

.filter-container {
  margin-bottom: 20px;
  border-radius: 8px;
  position: relative;
}

.filter-panel-header {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #222;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.2s ease;
  user-select: none;
}

.filter-panel-header:hover {
  background-color: #333;
}

.filter-panel-header.has-active-filters {
  background-color: #3a3a3a;
}

.filter-icon-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 18px;
  color: #bb86fc;
}

.filter-text {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.filters {
  display: flex;
  gap: 20px;
  padding: 20px 16px;
  background-color: #1a1a1a;
  border-radius: 0 0 8px 8px;
  flex-wrap: wrap;
  border-top: 1px solid #333;
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 768px) {
  .filters {
    padding-top: 1rem;
  }
}

.filters .select-container {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  touch-action: manipulation;
}

/* Style for react-select components */
.filters .select-container > div {
  width: 100%;
}

.filters .clear-button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.filters .clear-button:hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }

  .filters .select-container {
    flex: 0 0 calc(50% - 5px);
    max-width: calc(50% - 5px);
    margin-bottom: 10px;
  }

  /* Remove any existing min-width on the select containers */
  .filters .select-container {
    min-width: 0;
  }
}

/* Remove or comment out the separate 480px media query if it exists */

.clear-filters-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
  margin-top: 10px;
  margin-left: auto;
}

.clear-filters-button:hover {
  background-color: #444;
}

.clear-filters-button svg {
  font-size: 18px;
}