.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1e1e1e;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* We can keep the desktop styling for positioning but remove the TV-specific media query */
@media (min-width: 768px) {
  .bottom-nav {
    left: 50%;
    transform: translateX(-50%);
    max-width: 1200px; /* Match container max-width */
    width: 100%;
  }
}

.bottom-nav a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #888;
  font-size: 24px;
  transition: color 0.3s ease;
}

.bottom-nav a span {
  font-size: 12px;
  margin-top: 4px;
}

.bottom-nav a.active {
  color: #bb86fc;
}

.bottom-nav a:hover {
  color: #bb86fc;
} 