.hamburger-menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  /* We're now controlling visibility via JavaScript, so no need for display: none here */
}

.hamburger-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 32px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.hamburger-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-content {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100vh;
  background-color: #1e1e1e;
  padding: 80px 20px 20px;
  transition: right 0.3s ease;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.menu-content.open {
  right: 0;
}

.menu-content a {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  color: #888;
  font-size: 18px;
  padding: 15px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.menu-content a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #bb86fc;
}

.menu-content a.active {
  color: #bb86fc;
}

.menu-content a svg {
  font-size: 24px;
}

.sidebar-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 5%;
  height: 100vh;
  background-color: #1e1e1e;
  z-index: 1000;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  height: 100%;
  gap: 30px;
}

.sidebar-content a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #888;
  font-size: 24px;
  padding: 15px 0;
  width: 100%;
  transition: all 0.3s ease;
  position: relative;
}

.sidebar-content a:hover {
  color: #bb86fc;
}

.sidebar-content a.active {
  color: #bb86fc;
}

.sidebar-content a svg {
  font-size: 28px;
}

/* Tooltip that appears on hover */
.sidebar-content a .tooltip {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0,0,0,0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  margin-left: 10px;
  white-space: nowrap;
}

.sidebar-content a:hover .tooltip {
  opacity: 1;
  visibility: visible;
} 